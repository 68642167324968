import "./App.css";
import { useState } from "react";
import { send } from "emailjs-com";
function App() {
  const [toSend, setToSend] = useState({
    fullName: "",
    phone: "",
    email: "",
    message: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send("service_o59r5nb", "template_9izzgi7", toSend, "NmqesZZpTR19y1rsy")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
    setToSend({ fullName: "", phone: "", email: "", message: "" });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  return (
    <div className="App">
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nombre completo</label>
          <input
            type="text"
            className="form-control"
            name="fullName"
            required
            value={toSend.fullName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Teléfono</label>
          <input
            type="number"
            className="form-control"
            name="phone"
            required
            value={toSend.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Correo electrónico</label>
          <input
            type="email"
            className="form-control"
            name="email"
            aria-describedby="emailHelp"
            required
            value={toSend.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Mensaje</label>
          <textarea
            className="form-control"
            rows="5"
            name="message"
            required
            value={toSend.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          style={{ backgroundColor: "#EE6029" }}
        >
          Enviar
        </button>
      </form>
    </div>
  );
}

export default App;
